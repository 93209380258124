var exports = {};
function notTranspiledError(name) {
  throw new Error("styled-jsx/css: if you are getting this error it means that your `" + name + "` tagged template literals were not transpiled.");
}
function css() {
  notTranspiledError("css");
}
css.global = function () {
  notTranspiledError("global");
};
css.resolve = function () {
  notTranspiledError("resolve");
};
exports = css;
exports.global = css.global;
exports.resolve = css.resolve;
export default exports;
export const resolve = exports.resolve;
const _global2 = exports.global;
export { _global2 as global };